<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left">Data Banners</h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Data Banners
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <button
          class="btn btn-primary float-right px-4 my-2"
          @click.prevent="bannerForm"
        >
          Add Banner
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-6 col-md-4" v-for="(banner, p) in data" :key="p">
        <div class="card">
          <img
            class="card-img-top"
            :src="banner.file_endpoint"
            alt="Image For Banner"
          />
          <div class="card-body">
            <h5 class="card-title">{{ banner.title }}</h5>
            <p class="card-text">{{ banner.subtitle }}</p>
            <a
              class="btn btn-danger px-4"
              @click.prevent="deleteBanner(banner.id)"
              >Delete Banner</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
// import LoaderComponent from "../../components/Loader.vue";
export default {
  name: "Banner",
  // components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
    };
  },

  mounted() {
    this.getAllBanners();
  },

  methods: {
    getAllBanners() {
      this.loading = true;
      const endpoint = "banner";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.banner;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    bannerForm() {
      this.$router.push("/admin/banners/add");
    },

    deleteBanner(id) {
      Vue.swal({
        title: "Are you sure?",
        text: "This banner will be delete",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          const endpoint = `banner/${id}`;
          this.$api.delete(endpoint, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              Vue.swal("Success", "Banner deleted", "success").then(() => {
                this.getAllBanners();
              });
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }
      });
    },

    editBanner(id) {
      this.$router.push(`/admin/banners/edit/${id}`);
    },
  },
};
</script>
